import {Page} from "ionic-angular";

@Page({
  templateUrl: './build/pages/awesome/awesome.html'
})

export class AwesomePage {
    constructor() {
        this.slides = [{
                title: "Humor",
                description: "Ig schetze riisig, ds mr viu zäme chöi lache! Oh wes mängisch nur misverständnis si :)",
                image: "img/ica-slidebox-img-1.png"
            },
            {
                title: "Unkomplizierheit",
                description: "meh gits da nid z säge :)",
                image: "img/ica-slidebox-img-2.png"
            },
            {
                title: "Barmherzigkeit",
                description: "Finges dr hammer, wie du im chline probiersch für e besseri wält z sorge! (flüchtlinge, fairtrade schoggi/kaffi...)",
                image: "img/ica-slidebox-img-3.png"
            },
            {
                title: "Filmgschmack",
                description: "Starwars <3",
                image: "img/ica-slidebox-img-4.png"
            },
            {
                title: "Ehrlechkeit",
                description: "Bi hunne dankbar für dini offeheit/erhrlechkeit! me weiss ah was me isch.",
                image: "img/ica-slidebox-img-5.png"
            },
            {
                title: "Abenteuerlust",
                description: "Du lasch di für fasch alli abentür la begeistere (ussert vilich schwümme u co.) u bisch de aube all in!",
                image: "img/ica-slidebox-img-6.png"
            },
            {
                title: "Familiär",
                description: "Du leisch mega wert uf d family u ds öppis woni mega schön finge!",
                image: "img/ica-slidebox-img-7.png"
            },
            {
                title: "Vorusdänkend u schouend",
                description: "Ig bi öppr wo hunne im moment läbt u bi drum mega froh, öppr chönne ar siite z ha wo chli wiitr luegt :)",
                image: "img/ica-slidebox-img-8.png"
            },
            {
                title: "Hunne im gloube unterwägs",
                description: "Bi mega begeisteret wie du da ungerwägs bisch u bi gott mega dankbar drfür!",
                image: "img/ica-slidebox-img-9.png"
            }];
    }
}
